@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
    text-decoration: none;
}

input {
    transition: all 0.2s ease;
}

a {
    transition: all 0.2s ease;
}

button {
    transition: all 0.2s ease;
}

body,
html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}


.react-tabs__tab-list{
    border-bottom: 1px solid #b909ff !important;
    color: #b909ff;
}
.react-tabs__tab:focus{
    outline: none !important;
}

.react-tabs__tab--selected{
    border-color: #5f0782 !important;
    color: #fff !important;
    background: #5f0782 !important;
}

@layer base {

    .modal {
        @apply z-40 invisible opacity-0 transition-opacity max-w-[100vw] w-full h-[100vh] top-0 left-0 flex items-center justify-center fixed bg-[rgba(0,0,0,0.3)]
    }

    .active {
        @apply visible opacity-100 transition-opacity
    }

    .errInputText {
        @apply block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-red-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-red-500 focus:ring-inset sm:text-sm
    }

    .inputTextStyle {
        @apply block w-full rounded-md border-0 py-[9px] px-3 text-gray-900 shadow-sm ring-1 ring-gray-300  ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-primary focus:ring-inset sm:text-sm
    }
}


